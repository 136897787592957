/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */

import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Add, RestartAlt, Download } from '@mui/icons-material';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Visualize } from './StyledComponents';
import CourseRow from './components/CoursePanel/CourseRow';
import TermPanel from './components/TermPanel';
import TemplateSelection from './components/TemplateSelection';
import ConfirmModal from './components/ConfirmModal';
import CourseFetch from './hooks/CourseFetch';
import { CONFIG, CURRENT } from '../../ENV';
import StudentFetch from './hooks/StudentFetch';
import ProgramSemestersFetch from './hooks/ProgramSemestersFetch';
import TransactionFetch from './hooks/TransactionFetch';
import SelectedCoursesFetch from './hooks/SelectedCoursesFetch';
import StudentInfoPanel from './components/StudentInfoPanel';
import { SelectionsContext } from '../../context/SelectionsContext';
import { CertificationContext } from '../../context/CertificationContext';
import {
  determineLastSemester,
  getCorrPOSRequire,
} from '../../utils/termTools';
import MainCard from '../../components/MainCard';
import {
  aggregateCourseRowData,
  fabStyle,
  getTermsUntilLatest,
  isFulfilled,
} from './utils';
import CertificationPanel from './components/CertificationPanel';
import CrtfNoteFetch from './hooks/CrtfNoteFetch';
import { checkCertiTrack } from '../../utils/courseTools';
import MedTermRow from './components/CoursePanel/MedTermRow';
import studentServices from '../../api/services/studentServices';
import DownloadPdFButton from '../../components/DownloadPdFButton';
import { generateCurrentPlanPdf } from '../../utils/pdfTools';
import { missingPropertyCheck } from '../../utils/generalTools';
import { errorMssgExtract } from '../../utils/errorHandleTools';
import ErrorPage from '../ErrorPage';
import SemesterDropDown from './components/SemesterDropDown';

function CoursePlanner(props) {
  const history = useHistory();
  const authenticationStatus = useSelector(
    (state) => state.authenticationStatus
  );
  const role = authenticationStatus.authenticationRole;
  const { currSelState, currSelDispatch } = useContext(SelectionsContext);
  const { crtfState, crtfDispatch } = useContext(CertificationContext);
  // Initialize
  const [student, setStudent] = useState(null);
  const [courses, setCourses] = useState([]);
  const [programSemesters, setProgramSemesters] = useState([]);
  const [disCourseData, setDisCourseData] = useState([]);
  const [certificationSelect, setCertificationSelect] = useState({});
  const [medTerm, setMedTerm] = useState({});
  // Adhesive State
  const [transactionId, setTransactionId] = useState(0);
  const [transaction1, setTransaction1] = useState([]);
  const [transaction2, setTransaction2] = useState([]);
  const [POSReq, setPOSReq] = useState({});
  // State control
  const [resetFlag, setResetFlag] = useState(false);
  const [templateModalShow, setTemplateModalShow] = useState(false);
  const [openBulletinBoard, setOpenBulletinBoard] = useState(false);
  const [ConfirmModalShow, setConfirmModalShow] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [isError, setIsError] = useState(false);
  const [index, setIndex] = useState(0);
  const [certificationData, setCertificationData] = useState({});

  const [semesterSelection, setSemesterSelection] = useState('');
  const [studentDuration, setStudentDuration] = useState(0);
  const [changeSemester, setChangeSemester] = useState(0);
  const [saveTemplateButton, setSaveTemplateButton] = useState(false);
  const [updatedNotes, setUpdatedNotes] = useState({});

  // const [autoImportTemplate, setAutoImportTemplate] = useState(false);
  const fetchConfig = {
    headers: { Authorization: `Bearer ${authenticationStatus.jwtToken}` },
  };

  // Initialize
  useEffect(() => {
    async function init() {
      const id = Number(history.location.search.split('?')[1]);
      // Step1. Get student info
      const [studentLoading, studentError, studentData] = await StudentFetch(
        { id },
        fetchConfig
      );
      setStudent(studentData);
      const isInternational = studentData.studentInfo.isInternational;
      const key = missingPropertyCheck(
        studentData.studentInfo,
        ['start_term', 'start_year'],
        true
      );
      if (!(key === 'PASS')) {
        throw new Error(`Missing property ${key}, please set and try again`);
      }

      setMedTerm({
        didUpdate: false,
        med_terminology_year: studentData.studentInfo.med_terminology_year,
        med_terminology_term: studentData.studentInfo.med_terminology_term,
        med_terminology_fulfill:
          studentData.studentInfo.med_terminology_fulfill,
      });

      // Step2. Retrieve all available courses info
      const [courseLoading, courseError, courseData] = await CourseFetch(
        studentData.studentInfo.type
      );
      setCourses([...courseData]);

      // Step3. Get student semesters info
      if (changeSemester == 0) {
        setSemesterSelection(
          studentData.studentInfo.template_year
            ? studentData.studentInfo.template_year +
                studentData.studentInfo.template_term
            : studentData.studentInfo.start_year +
                studentData.studentInfo.start_term
        );
        setStudentDuration(studentData.studentInfo.program.duration);
        setProgramSemesters(
          getTermsUntilLatest(
            studentData.studentInfo.start_year +
              studentData.studentInfo.start_term,
            studentData.studentInfo.program.duration,
            determineLastSemester(currSelState)
          )
        );
      }

      // Step4. Get POS Template info
      const isCertificationTrack = checkCertiTrack(
        studentData.studentInfo.track
      );
      const POSData = getCorrPOSRequire(
        studentData.studentInfo,
        isCertificationTrack
      );
      setPOSReq(POSData);

      // Step5. Retrieve selected courses info
      const [selectionLoading, selectionError, selectionData] =
        await SelectedCoursesFetch({ studentId: id }, fetchConfig);
      currSelDispatch({ type: 'SET_SELECTION', payload: selectionData });

      // Step5.1 Retrieve certification notes info
      const [crtfNoteData] = await CrtfNoteFetch(id);
      crtfDispatch({ type: 'SET_CRTF', payload: crtfNoteData });

      // Step6. Retrieve transaction History
      const [transactionLoading, transactionError, transactionData] =
        await TransactionFetch({ studentId: id }, fetchConfig);
      const { data2, nextTransactionId, data1 } = transactionData;
      setTransactionId(nextTransactionId);
      setTransaction1(data1);
      setTransaction2(data2);

      // selection course
      setDisCourseData(
        aggregateCourseRowData(POSData, [...courseData], selectionData)
      );
    }

    init()
      .then(() => {
        setIsRendered(true);
      })
      .catch((error) => {
        setIsRendered(true);
        setIsError(true);
        toast.error(errorMssgExtract(error));
      });
  }, [changeSemester]);

  useEffect(() => {
    setDisCourseData(
      aggregateCourseRowData(POSReq, [...courses], currSelState)
    );
    if (student) {
      setProgramSemesters(
        getTermsUntilLatest(
          student.studentInfo.start_year + student.studentInfo.start_term,
          student.studentInfo.program.duration,
          determineLastSemester(currSelState)
        )
      );
    }
  }, [currSelState]);

  if (!isRendered) {
    return <LinearProgress />;
  }

  if (isRendered && isError) {
    return <ErrorPage />;
  }

  // Build-in function
  const displayMedTrm = () => {
    if (POSReq.Required['Medical Terminology']) {
      return (
        <MedTermRow
          title="Medical Terminology for Health Informatics"
          setMedTerm={setMedTerm}
          medTerm={medTerm.med_terminology_term}
          medYear={medTerm.med_terminology_year}
          medGrade={medTerm.med_terminology_fulfill}
        />
      );
    }
    return <></>;
  };

  // Auto-import courses function
  // const autoImportCrs = async (fileContent) => {
  //   if (fileContent.peoplesoft_id !== student.studentInfo.peoplesoft_id) {
  //     toast.error("You can't import this file to this student");
  //   } else {
  //     const res = await FetchAutoImportCourses(
  //       fileContent.courses,
  //       fileContent.status,
  //       fileContent.certifications
  //     );
  //     if (res.status === 200) {
  //       setIsRendered(false);
  //       toast.success('Success Import Courses');
  //       const { importCourses, tracks } = res.data;
  //       const formatData = {};
  //       // tracks.forEach((item) => {
  //       //   if (item.type === 'Certification') {
  //       //     formatData[item.id] = {
  //       //       check: true,
  //       //       crtfId: item.id,
  //       //       crtfTitle: item.full_name,
  //       //       content: [],
  //       //     };
  //       //   }
  //       // });
  //       importCourses.forEach((course) => {
  //         course.transactionId = transactionId;
  //       });
  //       const studentData = student.studentInfo;
  //       studentData.type =
  //         fileContent.status === 'part time' ? 'ONLINE' : 'ONCMP';
  //       const isCertificationTrack = checkCertiTrack(studentData.track);
  //       const POSData = getCorrPOSRequire(studentData, isCertificationTrack);
  //       currSelDispatch({ type: 'SET_SELECTION', payload: importCourses });
  //       setPOSReq(POSData);
  //       setDisCourseData(
  //         aggregateCourseRowData(POSReq, [...courses], importCourses)
  //       );
  //       setIsRendered(true);
  //     } else {
  //       toast.error('fail import');
  //     }
  //   }
  // };

  const addExtraIndeStudy = () => {
    // HI2999 is in othCrsData
    // [reqCrsData, eleCrsData, othCrsData] = disCourseData
    const indeStudyList = disCourseData[2].filter(
      (course) => course.courseId === '2999'
    );
    if (
      indeStudyList.length === 1 &&
      indeStudyList[0].courseDay === '' &&
      indeStudyList[0].courseTime === '' &&
      indeStudyList[0].courseYear === '' &&
      indeStudyList[0].courseTerm === '' &&
      indeStudyList[0].courseUid === '' &&
      (indeStudyList[0].courseNote === '' ||
        indeStudyList[0].courseNote === undefined)
    ) {
      toast.error('There is an empty row for HI2999');
    } else {
      setDisCourseData([
        disCourseData[0],
        disCourseData[1],
        [
          ...disCourseData[2],
          {
            ...indeStudyList[0],
            courseDay: '',
            courseTerm: CURRENT.TERM,
            coursesTime: '',
            courseType: undefined,
            courseYear: CURRENT.YEAR,
            courseUid: '',
            courseNote: '',
            suid: undefined,
          },
        ],
      ]);
    }
  };

  const displayCourses = () => {
    if (isRendered && courses.length !== 0) {
      const [reqCrsData, eleCrsData, othCrsData] = disCourseData;
      const isInternational = student?.studentInfo?.is_international;

      // Only count courses that have explicit selections for both term and type
      const termCounts = {};
      [...reqCrsData, ...eleCrsData, ...othCrsData].forEach((course) => {
        // Check if courseDay exists, indicating user has selected a course type
        if (
          course.courseType === 'ONLINE' &&
          course.courseYear &&
          course.courseTerm &&
          course.courseDay
        ) {
          // Add this check for courseDay
          const key = `${course.courseYear}-${course.courseTerm}`;
          termCounts[key] = (termCounts[key] || 0) + 1;
        }
      });

      // Helper to manage VISA note
      const manageVisaNote = (course) => {
        // Only proceed if all fields are explicitly selected
        if (
          !course.courseType ||
          !course.courseYear ||
          !course.courseTerm ||
          !course.courseDay
        ) {
          if (course.courseNote?.includes('VISA')) {
            const newNote = course.courseNote
              .replace(', VISA', '')
              .replace('VISA, ', '')
              .replace('VISA', '');
            return {
              ...course,
              courseNote: newNote === '' ? undefined : newNote,
            };
          }
          return course;
        }

        const key = `${course.courseYear}-${course.courseTerm}`;
        const shouldHaveVisa =
          isInternational &&
          course.courseType === 'ONLINE' &&
          termCounts[key] > 1;

        if (shouldHaveVisa) {
          if (!course.courseNote?.includes('VISA')) {
            return {
              ...course,
              courseNote: course.courseNote
                ? `${course.courseNote}, VISA`
                : 'VISA',
            };
          }
        } else {
          if (course.courseNote?.includes('VISA')) {
            const newNote = course.courseNote
              .replace(', VISA', '')
              .replace('VISA, ', '')
              .replace('VISA', '');
            return {
              ...course,
              courseNote: newNote === '' ? undefined : newNote,
            };
          }
        }
        return course;
      };

      return (
        <TableBody>
          {reqCrsData.length > 0 ? (
            <TableRow>
              <TableCell align="center" variant="head" colSpan={8}>
                Required Courses
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
          {displayMedTrm()}
          {reqCrsData.map((course) => (
            <CourseRow
              key={course.courseId + course.db_selectionId}
              isRequired
              courseData={manageVisaNote(course)}
              currSelDispatch={currSelDispatch}
              resetFlag={resetFlag}
              setResetFlag={setResetFlag}
              transactionId={transactionId}
              isInternational={isInternational}
              termCounts={termCounts}
              allCourseData={disCourseData.flat()}
            />
          ))}
          {eleCrsData.length > 0 ? (
            <TableRow>
              <TableCell align="center" variant="head" colSpan={8}>
                Elective Courses
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
          {eleCrsData.map((course) => (
            <CourseRow
              key={course.courseId + course.db_selectionId}
              courseData={manageVisaNote(course)}
              currSelDispatch={currSelDispatch}
              resetFlag={resetFlag}
              setResetFlag={setResetFlag}
              transactionId={transactionId}
              isInternational={isInternational}
              termCounts={termCounts}
              allCourseData={disCourseData.flat()}
            />
          ))}
          {othCrsData.length > 0 ? (
            <TableRow>
              <TableCell align="center" variant="head" colSpan={8}>
                Other Courses
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
          {othCrsData.map((course) => (
            <CourseRow
              key={course.courseId + course.db_selectionId}
              courseData={manageVisaNote(course)}
              currSelDispatch={currSelDispatch}
              resetFlag={resetFlag}
              setResetFlag={setResetFlag}
              transactionId={transactionId}
              isInternational={isInternational}
              termCounts={termCounts}
              allCourseData={disCourseData.flat()}
            />
          ))}
        </TableBody>
      );
    }
    return <TableRow />;
  };

  const handleSemesterChange = async (e) => {
    const semester = e.target.value;
    // const year = Number(semester.slice(0,4));
    // const term = semester.slice(4);
    setSemesterSelection(e.target.value);
    setSaveTemplateButton(true);
  };

  const saveTemplate = async () => {
    const year = Number(semesterSelection.slice(0, 4));
    const term = semesterSelection.slice(4);
    const result = await axios.post(
      `${CONFIG.PORTAL}/students/updateTemplate`,
      { student_id: student.id, template_year: year, template_term: term },
      fetchConfig
    );
    setSaveTemplateButton(false);
    setChangeSemester(changeSemester + 1);
  };

  const handleCertifData = () => {
    const format = [];
    courses.forEach((item) => {
      format.push({
        label: `HI ${item.courseId}`,
        value: `HI ${item.courseId} ${item.courseTitle}`,
        title: `HI ${item.courseId} ${item.courseTitle}`,
      });
    });
    return format;
  };

  const save = async () => {
    const isRunnable = isFulfilled(currSelState);
    if (isRunnable) {
      const lastSemester = determineLastSemester(currSelState);
      await new Promise((resolve, reject) => {
        axios
          .post(
            `${CONFIG.PORTAL}/students-courses/bulkAddCourseSelections`,
            {
              studentId: Number(student.id),
              courses: currSelState,
            },
            fetchConfig
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            toast.warning('Oops, unexpected error happened');
            console.error(error);
            reject(error);
          });
      })
        // eslint-disable-next-line arrow-body-style
        .then((data) => {
          return axios
            .post(
              `${CONFIG.PORTAL}/students/updateStudentGraduate`,
              {
                studentId: Number(student.id),
                lastSemester,
              },
              fetchConfig
            )
            .then((response) => response.data)
            .catch((error) => {
              toast.error('Oops, unexpected error');
              console.error(error);
            });
        })
        .then((response) => {
          toast.success(response);
          setTimeout(() => {
            window.location.reload();
          }, 100);
        });
      // console.log(crtfState);
      if (crtfState) {
        await new Promise((resolve, reject) => {
          axios
            .post(
              `${CONFIG.PORTAL}/student-certifications/bulkAddCrtfNote`,
              {
                studentId: Number(student.id),
                crtf: crtfState,
              },
              fetchConfig
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              toast.warning('Oops, unexpected error happened');
              console.error(error);
              reject(error);
            });
        });
      }

      if (medTerm.didUpdate) {
        await studentServices.editStudents(medTerm, student.studentInfo.id);
      }
    } else {
      toast.error('Please double check course selections');
    }
  };

  const downloadCurrentPlan = (
    currentSelection,
    // eslint-disable-next-line no-shadow
    programSemesters,
    studentData
  ) => {
    generateCurrentPlanPdf(currentSelection, programSemesters, studentData);
  };

  return (
    <div>
      <Grid container sx={{ pt: 0 }} rowSpacing={4.5} columnSpacing={2.75}>
        {/*Common Components*/}
        <ButtonGroup size="large" style={fabStyle}>
          <DownloadPdFButton studentData={student.studentInfo} />
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  color="secondary"*/}
          {/*  onClick={handleOpenBulletinBoard}*/}
          {/*>*/}
          {/*  {' '}*/}
          {/*  Bulletin{' '}*/}
          {/*</Button>*/}

          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  color="primary"*/}
          {/*  onClick={() => setAutoImportTemplate(true)}*/}
          {/*>*/}
          {/*  Auto-import{' '}*/}
          {/*</Button>*/}
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              downloadCurrentPlan(
                currSelState,
                programSemesters,
                student.studentInfo
              )
            }
          >
            <Download sx={{ mr: 0.5 }} />
            Course Sequence{' '}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setTemplateModalShow(true)}
          >
            <Add sx={{ mr: 0.5 }} />
            Auto-populate{' '}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setConfirmModalShow(true)}
          >
            <RestartAlt sx={{ mr: 0.5 }} />
            Reset{' '}
          </Button>
          <Button variant="contained" color="success" onClick={save}>
            Save{' '}
          </Button>
        </ButtonGroup>

        {/* Header */}
        <Grid item xs={12} sx={{ mb: -2.25 }}>
          <Typography variant="h5">Course Planner</Typography>
        </Grid>

        {/* Student Panel */}
        <Grid item xs={12}>
          <StudentInfoPanel
            student={student.studentInfo}
            currentSelection={currSelState}
          />
        </Grid>

        {/* Auto Import Courses Panel */}
        {/*<AutoImportCourses*/}
        {/*  autoImportCrs={autoImportCrs}*/}
        {/*  onHide={() => setAutoImportTemplate(false)}*/}
        {/*  show={autoImportTemplate}*/}
        {/*/>*/}

        {/* Course Selection Panel */}
        <Grid item xs={12}>
          <MainCard
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h5">Course Selection</Typography>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                  <SemesterDropDown
                    title="SELECT POS TEMPLATE : "
                    onChange={handleSemesterChange}
                    value={semesterSelection}
                    appearedSemester={programSemesters}
                  />
                  <div
                    style={{
                      marginLeft: 20,
                      display: saveTemplateButton ? 'block' : 'none',
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={saveTemplate}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            }
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tag</TableCell>
                  <TableCell>#</TableCell>
                  <TableCell>Course Title</TableCell>
                  <TableCell>Day & Time</TableCell>
                  <TableCell>Term</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>Grade</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              </TableHead>
              {displayCourses()}
            </Table>
            <div style={{ margin: 10 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={addExtraIndeStudy}
              >
                <Add sx={{ mr: 0.5 }} />
                Extra HI2999{' '}
              </Button>
            </div>
          </MainCard>
        </Grid>

        {/* Certification Panel */}
        <Grid item xs={12} sx={{ mb: '80px' }}>
          <CertificationPanel
            optionData={handleCertifData()}
            setCertificationSelect={setCertificationSelect}
            certificationNotes={
              POSReq.Certifications ? POSReq.Certifications : {}
            }
          />
        </Grid>

        {/* Current POS */}
        {/*<Grid item xs={12}>*/}
        {/*  <MainCard title="Course Sequence">*/}
        {/*    <Visualize>*/}
        {/*      <TermPanel*/}
        {/*        currentSelection={currSelState}*/}
        {/*        programSemesters={programSemesters}*/}
        {/*      />*/}
        {/*    </Visualize>*/}
        {/*  </MainCard>*/}
        {/*</Grid>*/}

        {/* Previous POS */}
        {/*<Grid item xs={12}>*/}
        {/*  <MainCard title="Previous Selections">*/}
        {/*    <Visualize>*/}
        {/*      <TermPanel*/}
        {/*        currentSelection={transaction1}*/}
        {/*        programSemesters={programSemesters}*/}
        {/*      />*/}
        {/*    </Visualize>*/}
        {/*    <Visualize>*/}
        {/*      <TermPanel*/}
        {/*        currentSelection={transaction2}*/}
        {/*        programSemesters={programSemesters}*/}
        {/*      />*/}
        {/*    </Visualize>*/}
        {/*  </MainCard>*/}
        {/*</Grid>*/}

        {/* Other models */}

        <TemplateSelection
          show={templateModalShow}
          student={student}
          setCurrentSelection={currSelDispatch}
          transactionId={transactionId}
          programSemesters={programSemesters}
          onHide={() => setTemplateModalShow(false)}
          fetchConfig={fetchConfig}
        />

        <ConfirmModal
          show={ConfirmModalShow}
          onHide={() => setConfirmModalShow(false)}
          setCurrentSelection={() =>
            currSelDispatch({ type: 'RESET_SELECTION', payload: {} })
          }
          setResetFlag={() => setResetFlag(true)}
        />
      </Grid>
    </div>
  );
}

export default CoursePlanner;
