import React from 'react';
import {
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Skeleton,
  Box,
} from '@mui/material';
import { Upload } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import MainCard from '../../components/MainCard';
import { Bar, Pie } from '@ant-design/plots';
import useChartStat from '../../api/hooks/useChartStat';

const fabStyle = {
  margin: 0,
  top: 'auto',
  right: 25,
  bottom: 20,
  left: 'auto',
  zIndex: 1500,
  position: 'absolute',
};

// Alumni Share Rate Component
const AlumniShareRate = () => {
  const { data: shareRateData, isLoading } = useChartStat(
    '/alumni-surveys/getAlumniSurveyShareRate'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const shareRate = shareRateData ? shareRateData.shareRate : 0;

  return (
    <MainCard sx={{ mt: 1, height: 405 }} content={false}>
      <Box
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1" align="center" color="primary">
          {shareRate}%
        </Typography>
      </Box>
    </MainCard>
  );
};

// Chart Components
const SurveyAttemptsChart = () => {
  const { data: surveyData, isLoading } = useChartStat(
    '/alumni-surveys/getSurveyAttemptCount'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const config = {
    data: surveyData || [],
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    isGroup: false,
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    layout: 'vertical',
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    legend: {
      position: 'top-left',
    },
  };

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Bar {...config} />
    </MainCard>
  );
};

const PathAfterGraduatingChart = () => {
  const { data: pathData, isLoading } = useChartStat(
    '/alumni-surveys/getPathAfterGradCount'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const config = {
    data: pathData || [],
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    isGroup: false,
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    layout: 'vertical',
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    legend: {
      position: 'top-left',
    },
  };

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Bar {...config} />
    </MainCard>
  );
};

const EmploymentRatioChart = () => {
  const { data: employmentData, isLoading } = useChartStat(
    '/alumni-surveys/getEmploymentCount'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const config = {
    data: employmentData || [],
    angleField: 'value',
    colorField: 'name',
    radius: 1,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
  };

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Pie {...config} />
    </MainCard>
  );
};

const EmploymentWorkSettingChart = () => {
  const { data: workSettingData, isLoading } = useChartStat(
    '/alumni-surveys/getEmploymentWorkSettingCount'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const config = {
    data: workSettingData || [],
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    isGroup: false,
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    layout: 'vertical',
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    legend: {
      position: 'top-left',
    },
  };

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Bar {...config} />
    </MainCard>
  );
};

const EmploymentJobTypeChart = () => {
  const { data: jobTypeData, isLoading } = useChartStat(
    '/alumni-surveys/getEmploymentJobTypeCount'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const config = {
    data: jobTypeData || [],
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    isGroup: false,
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    layout: 'vertical',
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    legend: {
      position: 'top-left',
    },
  };

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Bar {...config} />
    </MainCard>
  );
};

const HealthCareProfessionRateChart = () => {
  const { data: healthCareData, isLoading } = useChartStat(
    '/alumni-surveys/getHealthCareProfessionRate'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const config = {
    data: healthCareData || [],
    angleField: 'value',
    colorField: 'name',
    radius: 1,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
  };

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Pie {...config} />
    </MainCard>
  );
};

const NewEmploymentJobRateChart = () => {
  const { data: jobRateData, isLoading } = useChartStat(
    '/alumni-surveys/getNewEmploymentJobRate'
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const config = {
    data: jobRateData || [],
    angleField: 'value',
    colorField: 'name',
    radius: 1,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
  };

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Pie {...config} />
    </MainCard>
  );
};

const ContinueEducationAfterGraduateRatioChart = () => {
  const { data: continueEducationData, isLoading } = useChartStat(
    '/alumni-surveys/getConEduAfterGradRate'
  );

  // Check if data is loading
  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  // // Log the received data to inspect its structure
  // console.log('Received data for chart:', continueEducationData);

  // Render an empty box if the data is empty or invalid
  if (
    !continueEducationData ||
    continueEducationData.length === 0 ||
    !Array.isArray(continueEducationData) ||
    continueEducationData.every((item) => item.value === 0)
  ) {
    return <MainCard sx={{ mt: 1, height: 400 }} content={false}></MainCard>;
  }

  // Filter out items with 0 value if only one of them has a non-zero value
  const validData = continueEducationData.filter((item) => item.value !== 0);

  if (validData.length === 0) {
    return (
      <MainCard sx={{ mt: 1 }} content={false}>
        <Typography variant="body1" align="center" sx={{ p: 2 }}>
          No valid data available.
        </Typography>
      </MainCard>
    );
  }

  // Calculate total for percentages
  const total = validData.reduce((sum, item) => sum + item.value, 0);

  const config = {
    data: validData.map((item) => ({
      ...item,
      percent: total > 0 ? (item.value / total) * 100 : 100, // If only one value, it should be 100%
    })),
    angleField: 'value',
    colorField: 'name',
    radius: 1,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
  };

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Pie {...config} />
    </MainCard>
  );
};

// Main AlumniDashboard Component
function AlumniDashboard() {
  const history = useHistory();

  // Handler for importing surveys
  const handleToImportSurvey = () => {
    const path = '/AdminImportSurvey';
    history.push(path);
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* Button Group for Importing and Adding Surveys */}
      <ButtonGroup size="large" style={fabStyle}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleToImportSurvey}
        >
          <Upload sx={{ mr: 0.5 }} />
          Import Surveys
        </Button>
      </ButtonGroup>

      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Survey Dashboard</Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">Alumni Survey Share Rate</Typography>
        <AlumniShareRate />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">1. Survey Attempts</Typography>
        <SurveyAttemptsChart />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">2. Path After Graduating</Typography>
        <PathAfterGraduatingChart />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">3. Employment Ratio</Typography>
        <EmploymentRatioChart />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">4. Employment Work Setting</Typography>
        <EmploymentWorkSettingChart />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">5. Employment Job Type</Typography>
        <EmploymentJobTypeChart />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">6. Health Care Profession Rate</Typography>
        <HealthCareProfessionRateChart />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">7. New Employment Job Rate</Typography>
        <NewEmploymentJobRateChart />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">
          8. Continue Education After Graduate Ratio
        </Typography>
        <ContinueEducationAfterGraduateRatioChart />
      </Grid>
    </Grid>
  );
}

export default AlumniDashboard;
