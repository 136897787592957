/* eslint-disable no-nested-ternary */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { CURRENT } from '../ENV';
import {
  calculateTermValue,
  getCorrPOSRequire,
  semesterConvertor,
} from './termTools';
import {
  checkCertiTrack,
  convertTemplateToCourseArr,
  getTotalCredit,
  groupCourseData,
  isTaken,
} from './courseTools';
import { getMedFulfilledAbbr } from '../pages/StudentInfoView/InfoData';
import { isIncluded } from './generalTools';
import { isEmpty } from 'underscore';

const univName = 'University of Pittsburgh';
const schoolName = 'School of Health and Rehabilitation Sciences';
const deptName = 'Department of Health Information Management';
const currentSemester = `${CURRENT.YEAR} ${CURRENT.TERM}`;

// ============================ Corner case Functions ============================

function courseCreditProcess(courseSelectionObj) {
  const regex = new RegExp('#PASSCREDIT[1-6]{1}');
  if (
    courseSelectionObj.courseNote &&
    regex.test(courseSelectionObj.courseNote)
  ) {
    return courseSelectionObj.courseNote.match(regex)[0].slice(11);
  }
  return courseSelectionObj.courseCredit || 3;
}

function courseNoteProcess(courseSelectionObj) {
  const regex = new RegExp('#PASSCREDIT\\d+');
  if (
    courseSelectionObj.courseNote &&
    regex.test(courseSelectionObj.courseNote)
  ) {
    return courseSelectionObj.courseNote.replace(regex, '');
  }
  return courseSelectionObj.courseNote || '';
}

// ============================ PDF Functions ====================================

/**
 * Enrich course data from student-courses table according to courseId
 *
 * @param {Array} courseArr Array that contains course information
 * @param {Array} currentSelectionArr Array that contains selection information
 * @returns [{id, title, day, grade, taken takenCredit, totake}]
 */
const processCourseAndSelection = (courseArr, currentSelectionArr) => {
  const newCourse = currentSelectionArr.map((item) => {
    const newCourseItem = {};
    const courseSemester = item.courseYear
      ? `${item.courseYear} ${item.courseTerm}`
      : '';
    const prevFlag =
      calculateTermValue(currentSemester) >= calculateTermValue(courseSemester);
    newCourseItem.courseId = item.courseId;
    newCourseItem.courseTitle = item.courseTitle;
    newCourseItem.courseDay = item.courseDay;
    newCourseItem.courseGrade = item.courseGrade ? item.courseGrade : '';
    newCourseItem.courseTaken = prevFlag ? courseSemester : '';
    newCourseItem.courseCredit = courseCreditProcess(item);
    newCourseItem.courseToTake = prevFlag ? '' : courseSemester;
    newCourseItem.courseNote = courseNoteProcess(item);
    return newCourseItem;
  });
  courseArr.forEach((item) => {
    if (!isIncluded(newCourse, 'courseId', item.courseId)) {
      newCourse.push({
        courseId: item.courseId.toString(),
        courseTitle: item.courseTitle,
      });
    }
  });
  return newCourse;
};

/**
 *
 * @param {Array[Number]} ids course id labeled by 'or'
 * @param {Object} course Course Object
 * @param {String} idProperty id property name
 * @param {String} gradeProperty grade property name
 * @param {Boolean} previousFlag true if another 'or' course is in
 * @return boolean
 *
 *
 * 0 stands for don't display and don't update
 * 1 stands for display but don't update
 * 2 stands for display and update
 * 3 stands for on-hold and update
 */
const shouldBeSkipped = (
  ids,
  course,
  idProperty,
  gradeProperty,
  previousFlag
) => {
  // Don't skip if another or course is displayed
  for (let i = 0; i < ids.length; i++) {
    const item = ids[i];
    if (course[idProperty] == item) {
      if (isTaken(course, 'courseGrade', 'courseTaken')) {
        return 2;
      }
      if (previousFlag) {
        return 0;
      }

      return 3;
    }
  }
  return 1;
};

/**
 *
 * @param {Object} course1
 * @param {Object} course2
 * @return {[Object]|[Object,Object]}
 */
const orHandler = (course1, course2) => {
  const flag1 = isTaken(course1, 'courseGrade', 'courseTaken');
  const flag2 = isTaken(course2, 'courseGrade', 'courseTaken');
  if (flag1 && flag2) {
    return [course1, course2];
  }
  if (flag1 || !flag2) {
    return [course1];
  }
  return [course2];
};

/**
 * Insert row to table and apply data into PDF format
 *
 * @param {String} title
 * @param {number} credit
 * @param {Object[]} section
 * @param {array} target
 * @param {Object} studentData
 * @param {object} POSData
 */
const insertTableSection = (
  title,
  credit,
  section,
  target,
  studentData,
  POSData
) => {
  console.log(section);
  console.log(POSData);
  // For section title
  target.push([
    {
      text: `${title} Courses (${credit} credits)`,
      style: 'tableHeader',
      colSpan: 8,
      alignment: 'left',
    },
  ]);
  // For medical terminology row
  if (title === 'Required' && POSData.Required['Medical Terminology']) {
    const yearTermIndex = semesterConvertor(
      studentData.med_terminology_year,
      studentData.med_terminology_term
    );
    const currentYearTermIndex = semesterConvertor(CURRENT.YEAR, CURRENT.TERM);
    target.push([
      { text: '', fontSize: 8 },
      { text: POSData.Required['Medical Terminology'], fontSize: 8 },
      { text: '', fontSize: 8 },
      {
        text:
          yearTermIndex > currentYearTermIndex
            ? `${studentData.med_terminology_year} ${studentData.med_terminology_term}`
            : '',
        fontSize: 8,
      }, // year/term to take
      {
        text:
          yearTermIndex <= currentYearTermIndex && yearTermIndex !== -1
            ? `${studentData.med_terminology_year} ${studentData.med_terminology_term}`
            : '',
        fontSize: 8,
      }, // year term taken
      {},
      {
        text: studentData.med_terminology_fulfill
          ? getMedFulfilledAbbr(studentData.med_terminology_fulfill)
          : '',
        fontSize: 8,
      },
      {},
    ]);
  }

  // For general course row and handle OR case
  let orFlag = false;
  for (let i = 0; i < section.length; i++) {
    const course = section[i];
    if (
      Number(section[i].courseId) === 2451 &&
      i + 1 < section.length &&
      Number(section[i + 1].courseId) === 2455
    ) {
      orHandler(section[i], section[i + 1]).forEach((course) => {
        target.push([
          {
            text:
              (course.prefix ? course.coursePrefix : 'HI ') + course.courseId,
            fontSize: 8,
          },
          { text: course.courseTitle, fontSize: 8 },
          { text: course.courseDay, fontSize: 8 },
          { text: course.courseToTake, fontSize: 8 }, // year/term to take
          { text: course.courseTaken, fontSize: 8 }, // year term taken
          {
            text: course.courseCredit,
            fontSize: 8,
            alignment: 'center',
          },
          {
            text: course.courseGrade ? course.courseGrade : '',
            fontSize: 8,
            alignment: 'center',
          },
          { text: course.courseNote ? course.courseNote : '', fontSize: 8 },
        ]);
      });
      i++;
      continue;
    }
    // for POS pdf
    target.push([
      {
        text:
          course.courseId == '9999'
            ? course.courseId
            : (course.prefix ? course.coursePrefix : 'HI ') + course.courseId,
        fontSize: 8,
      },
      { text: course.courseTitle, fontSize: 8 },
      { text: course.courseDay, fontSize: 8 },
      { text: course.courseToTake, fontSize: 8 }, // year/term to take
      { text: course.courseTaken, fontSize: 8 }, // year term taken
      {
        text: course.courseCredit,
        fontSize: 8,
        alignment: 'center',
      },
      {
        text: course.courseGrade ? course.courseGrade : '',
        fontSize: 8,
        alignment: 'center',
      },
      { text: course.courseNote ? course.courseNote : '', fontSize: 8 },
    ]);
  }
};

// ============================ Mains ============================

/**
 * Function to generate PDF, download and open it, for button download POS
 *
 * @param selectionData - student course selection data
 * @param crtfData - certification data
 * @param studentData - student information
 * @param isFinal - draft/final POS
 */
const generatePdf = (selectionData, crtfData, studentData, isFinal = false) => {
  const POSData = getCorrPOSRequire(
    studentData,
    checkCertiTrack(studentData.track)
  );
  const processedSelectionData = isFinal
    ? selectionData.map((selection) => ({
        ...selection,
        courseNote: selection.courseNote
          ? selection.courseNote
              .replace(/, ?visa/gi, '')
              .replace(/visa, ?/gi, '')
              .replace(/visa/gi, '')
              .trim() || undefined
          : undefined,
      }))
    : selectionData;

  const courseArr = convertTemplateToCourseArr(POSData);
  const assemble = processCourseAndSelection(
    [...courseArr],
    processedSelectionData
  );
  const [requiredCourses, electiveCourses, otherCourses] = groupCourseData(
    POSData,
    [...assemble]
  );
  const reqCreditHeader = POSData.Credits.Required || 0;
  const eleCreditHeader = POSData.Credits.Elective || 0;
  const totalCredit = getTotalCredit(assemble, true);
  const track = studentData.track ? studentData.track : 'HI';

  const courseTableBody = [
    [
      { text: 'Course #', style: 'tableHeader' },
      { text: 'Title of Course', style: 'tableHeader' },
      { text: 'Day', style: 'tableHeader' },
      { text: 'Year/Term to Take', style: 'tableHeader' },
      { text: 'Year/Term Taken', style: 'tableHeader' },
      { text: 'Credit', style: 'tableHeader' },
      { text: 'Grade', style: 'tableHeader' },
      { text: 'Note', style: 'tableHeader' },
    ],
  ];

  if (requiredCourses.length > 0) {
    insertTableSection(
      'Required',
      reqCreditHeader,
      requiredCourses,
      courseTableBody,
      studentData,
      POSData,
      isFinal
    );
  }
  if (electiveCourses.length + otherCourses.length > 0) {
    insertTableSection(
      'Elective',
      eleCreditHeader,
      [...electiveCourses, ...otherCourses],
      courseTableBody,
      studentData,
      POSData,
      isFinal
    );
  }

  const crtfTable = [];
  crtfData.forEach((item) => {
    let followUpStr = ' | ';
    if (item.crtf_note && item.crtf_note.length > 0) {
      const note = item.crtf_note;
      note.forEach((subItem) => {
        followUpStr = `${followUpStr} ${subItem.label}`;
      });
    }
    crtfTable.push([
      { text: item.crtf_check ? '[ X ]' : '[\t]', alignment: 'center' },
      { text: `${item.crtf_name}${followUpStr}` },
    ]);
  });

  const pdfFormatConfig = {
    content: [
      { text: univName, style: 'header', alignment: 'center' },
      {
        text: schoolName,
        style: 'subheader',
        alignment: 'center',
      },
      {
        text: deptName,
        alignment: 'center',
        bold: false,
        fontSize: 11,
      },
      {
        text: `${track} Track Plan of Studies (${
          reqCreditHeader + eleCreditHeader
        } total credits)`,
        alignment: 'center',
        bold: false,
        fontSize: 11,
        margin: [0, 0, 0, 10],
      },
      {
        table: {
          body: [
            [
              'Gender',
              'LAST NAME \t FIRST NAME \t MIDDLE/MAIDEN NAME',
              'PEOPLESOFT ID',
            ],
            [
              `${studentData.gender || ''}`,
              `${studentData.last_name ? studentData.last_name : ''},\t\t${
                studentData.first_name ? studentData.first_name : ''
              },\t\t${studentData.middle_name ? studentData.middle_name : ''}`,
              studentData.peoplesoft_id,
            ],
          ],
          widths: ['auto', '*', 'auto'],
        },
        style: 'tableContent',
      },
      {
        table: {
          body: courseTableBody,
          widths: ['6%', '44%', '6%', '11%', '11%', '5%', '10%', '7%'],
        },
        style: 'tableContent',
      },
      {
        table: {
          body: [
            [
              {
                text: 'Check the box if you would like to pursue any of the following Certificates:',
                style: 'tableHeader',
                colSpan: 2,
                alignment: 'left',
              },
              {},
            ],
          ],
          widths: ['*', 'auto'],
        },
        style: 'tableContent',
      },
      {
        table: {
          body: crtfTable,
          widths: ['auto', '*'],
        },
        style: 'tableContent',
      },
      {
        table: {
          body: [
            [
              { text: 'STUDENT SIGNATURE', rowSpan: 2 },
              { text: 'DATE', rowSpan: 2 },
              { text: 'QPA' },
              { text: `TOTAL CREDITS: ${totalCredit}`, colSpan: 1 },
            ],
            [{}, {}, { text: 'ADVISOR SIGNATURE' }, { text: 'DATE' }],
          ],
          widths: ['25%', '25%', '25%', '25%'],
          heights: [15, 15],
        },
        style: 'tableContent',
      },
      {
        text: 'The requirement for the “Medical Terminology for Health Informatics” class may be fulfilled by taking this Coursera \n course offered by Pitt: https://www.coursera.org/learn/clinical-terminology',
        margin: [0, 10],
        fontSize: 8,
      },
      ...(isFinal
        ? []
        : [
            {
              text: 'NOTE: This is a draft version and may be subject to changes.',
              style: 'visaNote',
              alignment: 'center',
              margin: [0, 10],
            },
          ]),
    ],
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, -30, 0, 0],
        'text-align': 'center',
      },
      subheader: {
        fontSize: 10,
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: 'black',
        fillColor: '#C0C0C0',
        alignment: 'center',
      },
      tableContent: {
        fontSize: 7,
        color: 'black',
      },
      visaNote: {
        fontSize: 9,
        bold: true,
        color: 'red',
      },
    },
    pageMargins: [28, 55, 28, 28],
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.createPdf(pdfFormatConfig, null).open();
};

/**
 * Generate the PDF by current study plan
 *
 * @param {*} currentSelection - student course selection data
 * @param {*} programSemesters - program semesters
 * @param {*} studentData - student information
 */
const generateCurrentPlanPdf = (
  currentSelection,
  programSemesters,
  studentData
) => {
  const courseList = [];
  programSemesters.map((semester, index) => {
    // Push into array according to semester
    let sliceYear = semester.slice(0, 4);
    let sliceTerm = semester.slice(4, semester.length);
    let displayArr = [];
    currentSelection?.forEach((obj, index) => {
      if (
        obj.courseYear &&
        obj.courseYear.toString() === sliceYear &&
        obj.courseTerm === sliceTerm
      ) {
        displayArr.push({
          ...obj,
          mark: {
            isConflict: false,
            isValidity: true,
          },
        });
      }
    });

    // Conflict Checksum
    for (let i = 0; i < displayArr.length; i++) {
      let orgObj = displayArr[i];
      if (
        isEmpty(orgObj.courseDay) ||
        isEmpty(orgObj.courseTime) ||
        isEmpty(orgObj.courseTerm)
      ) {
        orgObj.mark.isValidity = false;
      }

      for (let j = 0; j < displayArr.length; j++) {
        let copObj = displayArr[j];
        if (
          copObj.courseId !== orgObj.courseId &&
          copObj.courseDay !== 'INDEP' &&
          copObj.courseTerm === orgObj.courseTerm &&
          copObj.courseYear === orgObj.courseYear &&
          copObj.courseTime === orgObj.courseTime &&
          copObj.courseDay === orgObj.courseDay
        ) {
          orgObj.mark.isConflict = true;
          copObj.mark.isConflict = true;
        }
      }
    }
    if (displayArr.length > 0) {
      var courses = {};
      courses.semester = semester;
      courses.displayArr = displayArr;
      courseList.push(courses);
    }
  });
  const courseTableBody = [
    [
      { text: 'Course #', style: 'tableHeader' },
      { text: 'Title of Course', style: 'tableHeader' },
      { text: 'Day', style: 'tableHeader' },
      { text: 'Time', style: 'tableHeader' },
      { text: 'Year/Term Taken', style: 'tableHeader' },
      { text: 'Credit', style: 'tableHeader' },
      { text: 'Grade', style: 'tableHeader' },
      { text: 'Note', style: 'tableHeader' },
    ],
  ];
  // For general course row
  courseList.map((item) => {
    courseTableBody.push([
      {
        text: item.semester,
        style: 'tableHeader',
        colSpan: 8,
        alignment: 'left',
      },
    ]);
    item.displayArr.forEach((course) => {
      // for course sequence
      courseTableBody.push([
        {
          text:
            course.courseId == '9999'
              ? course.courseId
              : (course.prefix ? course.coursePrefix : 'HI ') + course.courseId,
          fontSize: 8,
        },
        { text: course.courseTitle, fontSize: 8 },
        { text: course.courseDay, fontSize: 8 },
        { text: course.courseTime, fontSize: 8 }, // year/term to take
        { text: `${course.courseYear} ${course.courseTerm}`, fontSize: 8 }, // year term taken
        {
          text: courseCreditProcess(course),
          fontSize: 8,
          alignment: 'center',
        },
        {
          text: course.courseGrade ? course.courseGrade : '',
          fontSize: 8,
          alignment: 'center',
        },
        {
          text: course.courseNote
            ? courseNoteProcess(course)
            : `${course.mark.isConflict ? 'CONFLICT' : ''}`,
          fontSize: 6,
        },
      ]);
    });
  });
  const pdfFormatConfig = {
    content: [
      { text: univName, style: 'header', alignment: 'center' },
      {
        text: schoolName,
        style: 'subheader',
        alignment: 'center',
      },
      {
        text: deptName,
        alignment: 'center',
        bold: false,
        fontSize: 11,
      },
      {
        text: `${
          studentData.track ? studentData.track : 'HI'
        } - Current Plan of Studies`,
        alignment: 'center',
        bold: false,
        fontSize: 11,
        margin: [0, 0, 0, 10],
      },
      {
        table: {
          body: [
            [
              'MR. MS. MRS.',
              'LAST NAME \t FIRST NAME \t MIDDLE/MAIDEN NAME',
              'PEOPLESOFT ID',
            ],
            [
              `${studentData.gender || ''}`,
              `${studentData.last_name ? studentData.last_name : ''},\t\t${
                studentData.first_name ? studentData.first_name : ''
              },\t\t${studentData.middle_name ? studentData.middle_name : ''}`,
              studentData.peoplesoft_id,
            ],
          ],
          widths: ['auto', '*', 'auto'],
        },
        style: 'tableContent',
      },
      {
        table: {
          body: [
            [
              {
                // text: 'PRESENT MAILING ADDRESS \tSTREET\tCITY\tSTATE\tZIP CODE',
                text: 'PRESENT MAILING ADDRESS \tCITY\tSTATE\tZIP CODE',
                colSpan: 2,
              },
              {},
              'PHONE (AREA CODE & NO.)',
            ],
            [
              // {
              //   text: `${studentData.street ? studentData.street + ',' : ''}\t${
              //     studentData.city ? studentData.city + ',' : ''
              //   }\t${studentData.state ? studentData.state + ',' : ''}\t${
              //     studentData.zipcode ? studentData.zipcode + ',' : ''
              //   }`,
              //   colSpan: 2,
              // },
              {
                text: `${studentData.city ? studentData.city + ',' : ''}\t${
                  studentData.state ? studentData.state + ',' : ''
                }\t${studentData.zipcode ? studentData.zipcode + ',' : ''}`,
                colSpan: 2,
              },
              {},
              ' ',
            ],
          ],
          widths: ['*', 'auto', 'auto'],
        },
        style: 'tableContent',
      },
      {
        table: {
          body: [
            [
              {
                // text: 'PERMANENT MAILING ADDRESS\tSTREET\tCITY\tSTATE\tZIP CODE',
                text: 'PERMANENT MAILING ADDRESS\tCITY\tSTATE\tZIP CODE',
                colSpan: 2,
              },
              {},
              'PHONE (AREA CODE & NO.)',
            ],
            [{ text: ' ', colSpan: 2 }, {}, ' '],
          ],
          widths: ['*', 'auto', 'auto'],
        },
        style: 'tableContent',
      },
      {
        table: {
          body: [
            [
              {
                text: `STATUS:  FULL TIME [ ${
                  studentData.type === 'ONCMP' ? 'X' : '\t'
                } ] PART TIME [ ${
                  studentData.type === 'ONLINE' ? 'X' : '\t'
                } ]`,
              },
              'DATE ADMITTED:',
              {},
            ],
          ],
          widths: ['auto', 'auto', '*'],
        },
        style: 'tableContent',
      },
      {
        table: {
          body: courseTableBody,
          widths: ['6%', '44%', '6%', '11%', '11%', '5%', '10%', '7%'],
        },
        style: 'tableContent',
      },
    ],
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, -30, 0, 0],
        'text-align': 'center',
      },
      subheader: {
        fontSize: 10,
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: 'black',
        fillColor: '#C0C0C0',
        alignment: 'center',
      },
      tableContent: {
        fontSize: 7,
        color: 'black',
      },
    },
    defaultStyle: {
      // alignment: 'justify'
      // font: 'TimesNewRoman',
    },
    pageMargins: [28, 55, 28, 28],
  };
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.createPdf(pdfFormatConfig, null).open();
};

// eslint-disable-next-line import/prefer-default-export
export { generatePdf, generateCurrentPlanPdf };
