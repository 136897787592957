import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useStuListByAdv from '../../api/hooks/useStuListByAdv';
import SemesterDropDown from './SemesterDropDown';
import MainCard from '../../components/MainCard';
import StudentTable from './StudentTable';
import { ENROLLSTATUS } from '../../ENV';
import InfoData from '../StudentInfoView/InfoData';
import StudentTypeDropDown from './StudentTypeDropDown';
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  ButtonGroup,
} from '@mui/material';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { Download } from '@mui/icons-material';

const fabStyle = {
  margin: 0,
  top: 'auto',
  right: 50,
  bottom: 20,
  left: 'auto',
  zIndex: 1500,
  position: 'absolute',
};

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'track',
    headerName: 'Track',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatStartTerm',
    headerName: 'Start',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatGradTerm',
    headerName: 'Expt Grad',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'concatActualGradTerm',
    headerName: 'Grad',
    width: 120,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'plan_available',
    headerName: 'POS',
    type: 'boolean',
    width: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'medical_terminology',
    headerName: 'Med Status',
    type: 'boolean',
    width: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'advisor',
    headerName: 'Advisor',
    type: 'string',
    width: 180,
    headerAlign: 'center',
    align: 'center',
  },
];

export default function GraduationReport() {
  const { studentList, appearedSemester, isLoading, isError } =
    useStuListByAdv();
  const [gradSemester, setGradSemester] = useState('');
  const selectTypeOptions = ['ALL', 'ONCMP', 'ONLINE'];
  const [studentType, setStudentType] = useState(selectTypeOptions[0]);
  // debugger;

  if (isError) {
    toast.error(isError);
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  const handleChangeTerm = (e) => {
    const term = e.target.value;
    setGradSemester(term);
  };

  const handleChangeStudentType = (e) => {
    const studentType = e.target.value;
    setStudentType(studentType);
  };

  const mutateData = () => {
    let filtratedData = studentList.filter(
      (item) =>
        item.enrollment_status === ENROLLSTATUS.GRADUATE ||
        item.enrollment_status === ENROLLSTATUS.ACTIVE ||
        item.enrollment_status === ENROLLSTATUS.PROBATION
    );

    if (gradSemester) {
      filtratedData = filtratedData.filter(
        (item) => item.concatGradTerm === gradSemester
      );
    }

    if (studentType != 'ALL') {
      filtratedData = filtratedData.filter((item) => item.type === studentType);
    }
    return filtratedData;
  };

  const downloadCSV = () => {
    const data = mutateData(); // Table data
    const csvData = data.map((row) =>
      columns.reduce((acc, col) => {
        acc[col.headerName] = row[col.field] || ''; // Map data to column headers
        return acc;
      }, {})
    );
    const csv = Papa.unparse(csvData); // Convert JSON to CSV
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(
      blob,
      `Graduation_Report_${gradSemester || 'All'}_${
        studentType !== 'ALL' ? studentType : ''
      }.csv`
    );
  };

  return (
    <Grid container>
      {/* Title & Dropdown */}
      <Grid container alignItems="center" justifyContent="space-between">
        {!gradSemester && !studentType && (
          <Grid item>
            <Typography variant="h5">Graduation Report</Typography>
          </Grid>
        )}
        {(gradSemester || studentType) && (
          <Grid item>
            <Typography variant="h5">
              {gradSemester} {studentType}: {mutateData().length} student(s)
              graduate{' '}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SemesterDropDown
                title="SELECT EXPT GRADUATE SEMESTER : "
                value={gradSemester}
                onChange={handleChangeTerm}
                appearedSemester={appearedSemester}
              />
            </Grid>
            <Grid item>
              <StudentTypeDropDown
                title="SELECT STUDENT TYPE : "
                value={studentType}
                onChange={handleChangeStudentType}
                studentType={selectTypeOptions}
              />
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={downloadCSV}
              >
                <Download size="small" sx={{ mr: 0.5 }} />
                Report{' '}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Table */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <MainCard>
          <StudentTable studentList={mutateData()} headConfig={columns} />
        </MainCard>
      </Grid>
    </Grid>
  );
}
