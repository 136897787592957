import React, { useEffect, useState } from 'react';
import { Button, LinearProgress, Box } from '@mui/material';
import { Download } from '@mui/icons-material';
import { generatePdf } from '../utils/pdfTools';
import useSelectionByStuId from '../api/hooks/useSelectionByStuId';
import useCrtfByStuId from '../api/hooks/useCrtfByStuId';
import { useSelector } from 'react-redux';

function DownloadPdFButton({ studentData, variantConfig, colorConfig }) {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isWorkingPOS, setIsWorkingPOS] = useState(true);

  const authenticationStatus = useSelector(
    (state) => state.authenticationStatus
  );
  const role = authenticationStatus.authenticationRole;

  const { data: selectionData, isLoading: isLoadingA } = useSelectionByStuId(
    shouldFetch ? studentData.id : null
  );
  const { data: crtfData, isLoading: isLoadingB } = useCrtfByStuId(
    shouldFetch ? studentData.id : null
  );

  useEffect(() => {
    if (selectionData && crtfData) {
      const modifiedSelectionData = isWorkingPOS
        ? selectionData
        : selectionData.map((course) => ({
            ...course,
            note: course.note?.replace(/\bVISA\b/, '').trim(),
          }));

      generatePdf(modifiedSelectionData, crtfData, studentData, !isWorkingPOS);
      setShouldFetch(false);
    }
  }, [selectionData, crtfData, studentData, isWorkingPOS]);

  if (isLoadingA || isLoadingB) {
    return <LinearProgress />;
  }

  const handleClick = (isFinal) => {
    setIsWorkingPOS(!isFinal);
    setShouldFetch(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Button
        variant={variantConfig || 'contained'}
        color={colorConfig || 'secondary'}
        onClick={() => handleClick(false)}
      >
        <Download sx={{ mr: 0.5 }} />
        Draft POS
      </Button>

      {role === 'Staff' && (
        <Button
          variant={variantConfig || 'contained'}
          color="secondary"
          onClick={() => handleClick(true)}
        >
          <Download sx={{ mr: 0.5 }} />
          Final POS
        </Button>
      )}
    </Box>
  );
}

export default DownloadPdFButton;
